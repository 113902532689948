import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "../../data/header";
import HeaderLink from "./modules/HeaderLink";

const Header = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const MapLinks = () => {
    return (
      <>
        {links.map((data, key) => (
          <>
            {data.dropdown ? (
              <HeaderLink
                key={key}
                link={data.link}
                text={data.text}
                dropdown={data.dropdown}
              />
            ) : (
              <HeaderLink key={key} link={data.link} text={data.text} />
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <nav role="navigation">
      <header className="header" id="header">
        <Link className="logo" to="/" title="ELPD Home Page">
          <span className="logo-text">ELPD</span>
        </Link>
        <div
          className={`nav-toggle ${isNavOpen ? "open" : ""}`}
          onClick={toggleNav}
          title="Open Navigation Menu"
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links`}>
          <MapLinks />
        </ul>
        <ul className={`nav-links-mobile ${isNavOpen ? "open" : ""}`} lang="en">
          <MapLinks />
        </ul>
        <div className="right-wrapper">
          <div className="actions">
            <Link className="action" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </header>
    </nav>
  );
};

export default Header;
