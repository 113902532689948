import React, { useState } from "react";
import { Link } from "react-router-dom";

const HeaderLink = ({ key, link, text, dropdown }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState([]);

  const handleMouseEnter = (index) => {
    setIsDropdownVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    setIsDropdownVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const handleMouseEnterDropdown = (index) => {
    setIsDropdownVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeaveDropdown = (index) => {
    setIsDropdownVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };
  return (
    <li
      className="Link wrapper"
      key={key}
      onMouseEnter={() => handleMouseEnter(key)}
      onMouseLeave={() => handleMouseLeave(key)}
    >
      <Link className="nav-link" to={link} lang="en" title="%_Placeholder">
        {text}
      </Link>
      {dropdown && (
        <ul
          className={`dropdown ${isDropdownVisible[key] ? "open" : ""}`}
          onMouseEnter={() => handleMouseEnterDropdown(key)}
          onMouseLeave={() => handleMouseLeaveDropdown(key)}
        >
          {dropdown.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.text}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default HeaderLink;
