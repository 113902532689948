import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/scss/global/global.scss";
import * as Global from "../routers/global.router";
import * as Pages from "../routers/pages.router";

const App = () => {
  return (
    <Suspense fallback={<Global.Loading />}>
      <Routes>
        <Route path="/" element={<Global.Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Global.Loading />}>
                <Pages.Home />
              </Suspense>
            }
          />
          <Route path="*" element={<Global.NoPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
