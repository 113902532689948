const header = {
  navigation: {
    links: [
      {
        text: "About",
        link: "/about",
        dropdown: [
          {
            text: "About us",
            link: "/about/us",
          },
          {
            text: "Our Department",
            link: "/about/department",
          },
        ],
      },
      {
        text: "News",
        link: "/news",
      },
    ],
  },
};

const links = header.navigation.links.map((data) => ({
  text: data.text,
  link: data.link,
  ...(data.dropdown && { dropdown: data.dropdown }),
}));
console.log(links);

export default header;
export { links };
